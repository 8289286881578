import React from "react"
import styles from "./App.module.css"
import Search from "./components/Search"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Fragment } from "react"
import Results from "./components/Results"
import logo from "./digicust.png"
import { Button } from "@material-ui/core"

function App() {
  return (
    <Fragment>
      <Router>
        <body className={styles.App}>
          <img className={styles.logo} src={logo} alt='Digicust Logo' />
          <Search />
          <Switch>
            <Route path='/:description'>
              <Results />
            </Route>
            <Route path='/'></Route>
          </Switch>
          <a
            href='https://www.digicust.com/en/imprint/'
            className={styles.imprintLink}>
            Imprint
          </a>
        </body>
      </Router>
    </Fragment>
  )
}

export default App

import React, { useState, useEffect } from "react"
import styles from "./Results.module.css"
import { useParams } from "react-router-dom"
import { CircularProgress, Chip } from "@material-ui/core"
import axios from "axios"

export default function Results() {
  let { description } = useParams()
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (description && description.length > 0) {
      setLoading(true)
      axios
        .get(
          "https://us-central1-digicust-hs-classifier.cloudfunctions.net/hsCodes1?description=" +
            encodeURI(description)
        )
        .then(function (response) {
          setResults(response.data)
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          setLoading(false)
        })
    }
  }, [description])

  return (
    <div className={styles.Results}>
      {loading ? (
        <CircularProgress />
      ) : (
        results.map((result) => (
          <div className={styles.result}>
            <h1 className={styles.h1}>{result.hsNumber}</h1>
            <p className={styles.description}>{result.description}</p>
            {/* <p className={styles.category}>{result.parentDescription}</p> */}
            <div className={styles.footer}>
              <div className={styles.tags}>
                {/* <Chip
                  className={styles.tag}
                  label={"~ " + Math.round(result.price) + "€/item"}
                  variant='outlined'
                /> */}
              </div>
              <div className={styles.accuracy}>
                <span className={styles.accuracyText}>
                  {(100 * result.count) / 10} %
                </span>
                <CircularProgress
                  variant='static'
                  value={(100 * result.count) / 10}
                />
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

import React, { useState } from "react"
import styles from "./Search.module.css"
import TextField from "@material-ui/core/TextField"
import { withRouter } from "react-router-dom"

export default withRouter(function Search({ history }) {
  const [searchTerm, setSearchTerm] = useState("")
  return (
    <div className={styles.Search}>
      <form
        noValidate
        autoComplete='off'
        onSubmit={(e) => {
          e.preventDefault()
          history.push("/" + searchTerm)
        }}
        method='get'>
        <TextField
          className={styles.TextField}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          label='Find HS number by goods description'
          variant='outlined'
        />
      </form>
    </div>
  )
})
